<template>
  <div>
    <ValidationObserver v-slot="context">
      <form @submit.prevent="submit(context)">
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>ชื่อ LH Community </span><span class="text-danger">*</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="ชื่อ" 
                rules="required">
                <vs-input 
                  v-model="formData.title" 
                  class="w-full" 
                  name="title" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รูป LH Community </span><span class="text-danger">*</span>
              <br >
              <span class="text-warning text-sm">(ความกว้างต่ำสุด 500px - ความกว้างสูงสุด 1920px)</span>
              <br >
              <span class="text-warning text-sm">(ไฟล์ jpg , gif , png)</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="รูปภาพ"
                rules="required|lh_mimes:jpg,png|lh_image_min_width:500|lh_image_max_width:1920"
              >
                <ImageUpload
                  :folder="`${baseFolder}/lh-community/${formData.folder_name}/Image`"
                  :has-delete-button="canDelete(formData.thumbnail)"
                  v-model="formData.thumbnail"
                  selection-name="thumbnail"
                  crop="scale"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รายละเอียด โดยย่อ </span><span class="text-danger">*</span>
              <br>
              <span>ใส่ข้อความไม่เกิน 200 คำ</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="รายละเอียด" 
                rules="required|max:200">
                <vs-textarea
                  v-model="formData.description"
                  class="w-full"
                  rows="6"
                  name="description"
                  :maxlength="200"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>บทสัมภาษณ์ </span><span class="text-danger">*</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="บทสัมภาษณ์" 
                rules="required">
                <vs-textarea
                  v-model="formData.content"
                  class="w-full"
                  name="content"
                  rows="8"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
        </vx-card>
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดโดย</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formData.updated_by}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดวันที่</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel/>
            <FormContent class="flex space-x-4">
              <vs-button 
                @click="submit(context)"
              >
                {{ formData.id ? 'Update' : 'Submit' }}
              </vs-button>
              <vs-button 
                v-if="formData.id !== null" 
                @click="preview()"
              >
                Preview
              </vs-button>
            </FormContent>
          </FormLayout>
        </vx-card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { onMounted, computed } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import Datepicker from 'vuejs-datepicker'
import { quillEditor } from 'vue-quill-editor'
import env from '@/env'
import ImageUpload from '@/components/upload/ImageUpload'
import useDatepicker from '@/use/useDatepicker'
import useCommunityUtil from '@/use/useCommunityUtil'
import useCrud from '@/use/useCrud'
import useNotify from '@/use/useNotify'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import omit from 'lodash/omit'

export default {
  name: 'CommunityForm',
  components: {
    ImageUpload,
    Datepicker,
    ValidationObserver,
    QuillEditor: quillEditor,
  },
  setup(props, ctx) {
    const { success: notifySuccess, error: notifyError, ...notifyFn } = useNotify(ctx)
    const {
      root: { $store, $router, $vs },
    } = ctx
    const { initialData, formData, categories, fetchByRouteId } = useCommunityUtil(ctx)
    const crudForm = useCrud(ctx, 'community')
    crudForm.routePrefix.value = 'lh-content-community'

    onMounted(() => {
      fetchByRouteId()
    })

    const updateItem = ({ validate }) => {
      validate().then((result) => {
        if (!result) {
          notifyError({
            text: 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง'
          })
          return
        }

        $vs.loading()
        const activeUser = ctx.root.$store.state.AppActiveUser
        const email = get(activeUser, 'email', '')
        let created_by = formData.created_by
        const updated_by = email

        // add user created by
        if (!created_by) {
          created_by = email
        }

        $store
          .dispatch('community/updateItem', { initialData,
            formData: {
              ...omit(formData, ['updated_at']),
              created_by,
              updated_by
            }
           })
          .then(() => {
            $router.push({ name: 'lh-content-community' })
            $vs.loading.close()
            notifySuccess({ text: `${formData.id ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลเรียบร้อย` })
          })
          .catch((error) => {
            $vs.loading.close()
            if (
              error.message.split('unique constraint "content_slug_lang_key"').length > 1
            ) {
              notifyError({
                text:
                  'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากชื่อ หรือ URL พิเศษซ้ำกับข้อมูลที่มีอยู่ในระบบ',
              })
            } else {
              notifyError({ text: JSON.stringify(error) })
            }
          })
      })
    }

    const submit = (ctx) => {
      formData.title = formData.title.trim()
      formData.slug = formData.title.replace(/[!@#$%^&*)(+=.?]/g, "").trim().split(' ').join('-').replace(/[!@#$%^&*)(+=.?]/g, "")
      formData.published_date = new Date()

      if (formData.id) {
        notifyFn.confirm({
          callback: () => { updateItem(ctx) }
        })
      } else {
        crudForm.addItem(ctx, formData)
      }
    }

    const preview = () => {
      const lang = $store.state.locale.currentLang
      window.open(`${env.VUE_APP_WEBSITE_URL}/${lang}/lh-community`)
    }

    const canDelete = (files) => {
      return !isEmpty(files)
    }

    const baseFolder = computed(() => env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)

    return {
      ...crudForm,
      updateItem,
      formData,
      categories,
      submit,
      preview,
      useDatepicker: useDatepicker(),
      baseFolder,
      canDelete
    }
  },
}
</script>
