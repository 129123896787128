var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(context){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit(context)}}},[_c('vx-card',{staticClass:"mb-base"},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ชื่อ LH Community ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ชื่อ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"title"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("รูป LH Community ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("(ความกว้างต่ำสุด 500px - ความกว้างสูงสุด 1920px)")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("(ไฟล์ jpg , gif , png)")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"รูปภาพ","rules":"required|lh_mimes:jpg,png|lh_image_min_width:500|lh_image_max_width:1920"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/lh-community/" + (_vm.formData.folder_name) + "/Image"),"has-delete-button":_vm.canDelete(_vm.formData.thumbnail),"selection-name":"thumbnail","crop":"scale"},model:{value:(_vm.formData.thumbnail),callback:function ($$v) {_vm.$set(_vm.formData, "thumbnail", $$v)},expression:"formData.thumbnail"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("รายละเอียด โดยย่อ ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('br'),_c('span',[_vm._v("ใส่ข้อความไม่เกิน 200 คำ")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"รายละเอียด","rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",attrs:{"rows":"6","name":"description","maxlength":200},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("บทสัมภาษณ์ ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"บทสัมภาษณ์","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",attrs:{"name":"content","rows":"8"},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1)],1),_c('vx-card',{staticClass:"mb-base"},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดโดย")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formData.updated_by))])])],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดวันที่")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.formData.updated_at)))])])],1),_c('FormLayout',[_c('FormLabel'),_c('FormContent',{staticClass:"flex space-x-4"},[_c('vs-button',{on:{"click":function($event){return _vm.submit(context)}}},[_vm._v("\n              "+_vm._s(_vm.formData.id ? 'Update' : 'Submit')+"\n            ")]),(_vm.formData.id !== null)?_c('vs-button',{on:{"click":function($event){return _vm.preview()}}},[_vm._v("\n              Preview\n            ")]):_vm._e()],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }